
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch, Prop} from 'vue-property-decorator';
    import {formHelper} from '@/helpers';

    import {
        IVeterinarian,
        IVeterinarianEducation,
        IUpdateVeterinarianEducationsParams,
        IUpdateOrganizationVeterinarianEducationsParams,
        IOrganization,
    } from '@/types';

    import {
        VeterinarianModule,
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const organizationNamespace = namespace('organization');
    const veterinarianNamespace = namespace('veterinarian');

    @Component<AccountProfileEducation>({
        components: {
        },
    })
    export default class AccountProfileEducation extends Vue {
        public formValid: boolean = false;
        public isSubmittingProfileEducation: boolean = false;
        public educationNameRules = formHelper.getAccountEducationNameRules();
        public educationLocationRules = formHelper.getAccountEducationLocationRules();
        public educationStartYearRules = formHelper.getAccountEducationStartYearRules();
        public educationEndYearRules = formHelper.getAccountEducationEndYearRules();
        public newEducationName: string = '';
        public newEducationLocation: string = '';
        public newEducationStartYear: number|null = null;
        public newEducationEndYear: number|null = null;

        public innerModel!: IVeterinarian;

        @veterinarianNamespace.Getter('loggedVeterinarian')
        public loggedVeterinarian!: IVeterinarian;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;


        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian!: IVeterinarian;

        public addNewEducation() {
            const newEducation: IVeterinarianEducation = {
                name: this.newEducationName,
                place: this.newEducationLocation,
                start: this.newEducationStartYear,
            };
            if(this.newEducationEndYear) {
                newEducation.end = this.newEducationEndYear;
            }

            this.innerModel.educations.push(newEducation);
            this.submitUpdateAccountEducations();
            this.newEducationName = '';
            this.newEducationLocation = '';
            this.newEducationStartYear = null;
            this.newEducationEndYear = null;
            (this.$refs.form as any).reset();
        }

        public deleteCertification(index: number) {
            (this.$refs.form as any).reset();
            this.innerModel.educations.splice(index, 1);
            this.submitUpdateAccountEducations();
        }

        public submitUpdateAccountEducations() {
            this.isSubmittingProfileEducation = true;
            const veterinarianModule = getModule(VeterinarianModule, this.$store);
            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            if (this.loggedVeterinarian && (this.veterinarian.id === this.loggedVeterinarian.id)) {
                const params: IUpdateVeterinarianEducationsParams = {
                    veterinarian_id: this.veterinarian.id,
                    educations: this.innerModel.educations,
                };

                veterinarianModule
                    .update(params as IUpdateVeterinarianEducationsParams)
                    .then((veterinarian: IVeterinarian) => {
                        snackbarModule.displaySuccess(`Votre profil a bien été modifié !`);
                        this.$emit('input', veterinarian);
                    })
                    .catch(() => {
                        snackbarModule.displayError();
                    })
                    .finally(() => {
                        this.isSubmittingProfileEducation = false;
                    })
                ;
            } else {
                const params: IUpdateOrganizationVeterinarianEducationsParams = {
                    organization_id: this.loggedOrganization.id,
                    veterinarian_id: this.veterinarian.id,
                    educations: this.innerModel.educations,
                };

                organizationModule
                    .updateOrganizationVeterinarian(params as IUpdateOrganizationVeterinarianEducationsParams)
                    .then((veterinarian: IVeterinarian) => {
                        snackbarModule.displaySuccess(`Votre profil a bien été modifié !`);
                        this.$emit('input', veterinarian);
                    })
                    .catch(() => {
                        snackbarModule.displayError();
                    })
                    .finally(() => {
                        this.isSubmittingProfileEducation = false;
                    })
                ;
            }
        }

        private data() {
            return {
                innerModel: this.veterinarian ? JSON.parse(JSON.stringify(this.veterinarian)) : null,
            };
        }

        @Watch('veterinarian', {immediate: true})
        private onVeterinarianChange(newVal: string) {
            this.innerModel = newVal ? JSON.parse(JSON.stringify(newVal)) : null;
        }
    }
