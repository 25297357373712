
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Cropper, CircleStencil} from 'vue-advanced-cropper';
    import {DateTime} from 'luxon';

    @Component<EditAvatarDialog>({
        components: {
            Cropper,
            CircleStencil,
        },
        props: {
            dialog: {
                type: Boolean,
                default: false,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
    })

    export default class EditAvatarDialog extends Vue {
        public chosenImage: File|null = null;
        public chosenImageBase64: string | null = null;

        public crop() {
            const {coordinates, canvas}: {coordinates: any, canvas: HTMLCanvasElement} =
                (this.$refs.cropper as any).getResult();

            canvas.toBlob((blob: Blob|null) => {
                if (!blob) {
                    return;
                }

                const file = new File(
                    [blob],
                    (this.chosenImage as File).name,
                    {
                        lastModified: DateTime.utc().toMillis(),
                    },
                );

                this.$emit('cropped', file);
            }, 'image/jpeg');
        }

        public async convertChosenImageToBase64() {
            if (!this.chosenImage) {
                return;
            }

            const toBase64: any = (file: File) => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error: any) => reject(error);
            });

            const image = await toBase64(this.chosenImage);
            this.chosenImageBase64 = image;
        }
    }
