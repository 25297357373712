
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import {
        IOrganizationPicture,
    } from '@/types';

    @Component<PictureDropzone>({})
    export default class PictureDropzone extends Vue {
        @Prop({required: true})
        public pictures!: IOrganizationPicture[];

        @Prop({required: true})
        public isUploadingPictures!: boolean;

        @Prop({required: true})
        public files!: Array<{src:string | ArrayBuffer | null, fileInfos:File}>;

        public errorMessage: string = '';

        public dropPicture(event: {dataTransfer: any}) {
            for(const file of event.dataTransfer.files) {
                // Gestion d'erreur
                if (file.type.slice(0,5) !== 'image') {
                    this.errorMessage = 'Vous pouvez seulement importer des images';
                    return;
                }

                const index = this.files.push({
                    fileInfos: file,
                    src: null,
                }) - 1;

                const reader = new FileReader();
                reader.onload = () => {
                    this.files[index].src = reader.result;
                };

                reader.readAsDataURL(file);
                this.errorMessage = '';
            }

            this.$emit('upload-pictures', this.files);
        }

        public loadPicturesFromFileSystem(e: any) {
            for(const file of e.target.files) {
                const index = this.files.push({
                    fileInfos: file,
                    src: null,
                }) - 1;

                const reader = new FileReader();
                reader.onload = () => {
                    this.files[index].src = reader.result;
                };

                reader.readAsDataURL(file);
            }

            this.$emit('upload-pictures', this.files);
        }
    }
