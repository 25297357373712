
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch, Prop} from 'vue-property-decorator';
    import {imageHelper} from '@/helpers';
    import PictureDropzone from '@/components/PictureDropzone.vue';

    import {
        IAccount,
        IPostVeterinarianPicturesParams,
        IPostVeterinarianPicturesResult,
        IPostVeterinarianPicturesError,
        IDeleteVeterinarianPictureParams,
        IVeterinarian,
        IOrganization,
        IPostOrganizationVeterinarianPicturesParams,
        IDeleteOrganizationVeterinarianPictureParams,
    } from '@/types';

    import {
        VeterinarianModule,
        OrganizationModule,
        SnackbarModule,
    } from '@/store/modules';

    const veterinarianNamespace = namespace('veterinarian');
    const organizationNamespace = namespace('organization');

    @Component<AccountProfilePictures>({
        components: {
            PictureDropzone,
        },
    })
    export default class AccountProfilePictures extends Vue {
        public innerModel!: IAccount;
        public isUploadingPictures: boolean = false;
        public files: File[] = [];

        @veterinarianNamespace.Getter('loggedVeterinarian')
        public loggedVeterinarian!: IVeterinarian;

        @organizationNamespace.Getter('loggedOrganization')
        public loggedOrganization!: IOrganization;

        @Prop({
            type: Object,
            required: false,
        })
        public veterinarian!: IVeterinarian;

        public deletePicture(pictureId: string) {
            const organizationModule = getModule(OrganizationModule, this.$store);
            const veterinarianModule = getModule(VeterinarianModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            if (this.loggedVeterinarian && (this.veterinarian.id === this.loggedVeterinarian.id)) {
                const params: IDeleteVeterinarianPictureParams = {
                    veterinarian_id: this.veterinarian.id,
                    picture_id: pictureId,
                };

                veterinarianModule
                    .deletePicture(params)
                    .then((response: boolean) => {
                        snackbarModule.displaySuccess('L\'image a bien été supprimée');
                    })
                    .catch(() => snackbarModule.displayError())
                    .finally(() => this.isUploadingPictures = false);
            } else {
                const params: IDeleteOrganizationVeterinarianPictureParams = {
                    organization_id: this.loggedOrganization.id,
                    veterinarian_id: this.veterinarian.id,
                    picture_id: pictureId,
                };

                organizationModule
                    .deleteOrganizationVeterinarianPicture(params)
                    .then((response: boolean) => {
                        snackbarModule.displaySuccess('L\'image a bien été supprimée');
                    })
                    .catch(() => snackbarModule.displayError())
                    .finally(() => this.isUploadingPictures = false);
            }

        }

        public async uploadPictures(pictures: File[]) {
            this.isUploadingPictures = true;
            this.files = pictures;

            Promise
                .all(
                    this.files.map((file: any) => {
                        return imageHelper.resizeImage({
                            maxWidth: 1920,
                            maxHeight: 1080,
                            file: file.fileInfos,
                        });
                    }),
                )
                .then(this.sendUploadPicturesRequest)
            ;
        }

        private sendUploadPicturesRequest(files: File[]) {
            const formData = new FormData();

            files.map((file, index) => {
                formData.append(`pictures[${index}]`, file);
            });

            const veterinarianModule = getModule(VeterinarianModule, this.$store);
            const organizationModule = getModule(OrganizationModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            if (this.loggedVeterinarian && (this.veterinarian.id === this.loggedVeterinarian.id)) {
                const params: IPostVeterinarianPicturesParams = {
                    veterinarian_id: this.veterinarian.id,
                    pictures: formData,
                };

                veterinarianModule
                    .postPictures(params)
                    .then((response: IPostVeterinarianPicturesResult) => {
                        if (response.errors.length > 0) {
                            const failedFiles =
                                response.errors.map((value: IPostVeterinarianPicturesError) => {
                                    return value.file;
                                })
                                    .join(', ')
                            ;
                            snackbarModule.displayError(`Une erreur est survenue pendant l'envoi des images suivantes : ${failedFiles}`);
                        } else {
                            snackbarModule.displaySuccess('Vos images ont bien été ajoutées !');
                        }
                    })
                    .catch(() => snackbarModule.displayError())
                    .finally(() => {
                        this.isUploadingPictures = false;
                        this.files = [];
                    })
                ;
            } else {
                const params: IPostOrganizationVeterinarianPicturesParams = {
                    organization_id: this.loggedOrganization.id,
                    veterinarian_id: this.veterinarian.id,
                    pictures: formData,
                };

                organizationModule
                    .postOrganizationVeterinarianPictures(params)
                    .then((response: IPostVeterinarianPicturesResult) => {
                        if (response.errors.length > 0) {
                            const failedFiles =
                                response.errors.map((value: IPostVeterinarianPicturesError) => {
                                    return value.file;
                                })
                                    .join(', ')
                            ;
                            snackbarModule.displayError(`Une erreur est survenue pendant l'envoi des images suivantes : ${failedFiles}`);
                        } else {
                            snackbarModule.displaySuccess('Vos images ont bien été ajoutées !');
                        }
                    })
                    .catch(() => snackbarModule.displayError())
                    .finally(() => {
                        this.isUploadingPictures = false;
                        this.files = [];
                    })
                ;
            }
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.veterinarian),
            };
        }

        @Watch('veterinarian', {immediate: true})
        private onVeterinarianChange(newVal: IAccount) {
            this.innerModel = Object.assign({}, newVal);
        }
    }
