
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import {Watch} from 'vue-property-decorator';

    import {
        IParaveterinarian,
        IUpdateParaveterinarianPresentationParams,
    } from '@/types';

    import {
        ParaveterinarianModule,
        SnackbarModule,
    } from '@/store/modules';

    const paraveterinarianNamespace = namespace('paraveterinarian');

    import PhoneInput from '@/components/forms/PhoneInput.vue';

    @Component<AccountProfilePresentationParaveterinarian>({
        components: {
            PhoneInput,
        },
    })
    export default class AccountProfilePresentationParaveterinarian extends Vue {
        public formValid: boolean = false;
        public isSubmittingAccountProfile: boolean = false;

        @paraveterinarianNamespace.Getter('loggedParaveterinarian')
        public loggedParaveterinarian!: IParaveterinarian;

        public innerModel!: IParaveterinarian;

        get params(): IUpdateParaveterinarianPresentationParams {
            return {
                paraveterinarian_id: this.innerModel.id,
                first_name: this.innerModel.first_name,
                last_name: this.innerModel.last_name,
                cell_phone: this.innerModel.cell_phone,
            };
        }

        public submitUpdateAccount() {
            this.isSubmittingAccountProfile = true;
            const paraveterinarianModule = getModule(ParaveterinarianModule, this.$store);
            const snackbarModule = getModule(SnackbarModule, this.$store);

            paraveterinarianModule
                .update(this.params as IUpdateParaveterinarianPresentationParams)
                .then((paraveterinarian: IParaveterinarian) => {
                    snackbarModule.displaySuccess(`Votre profil a bien été modifié !`);
                    this.$emit('input', paraveterinarian);
                })
                .catch(() => {
                    snackbarModule.displayError();
                })
                .finally(() => {
                    this.isSubmittingAccountProfile = false;
                })
            ;
        }

        private data() {
            return {
                innerModel: Object.assign({}, this.loggedParaveterinarian),
            };
        }

        @Watch('loggedParaveterinarian', {immediate: true})
        private onLoggedParaveterinarianChange(newVal: IParaveterinarian) {
            this.innerModel = Object.assign({}, newVal);
        }
    }
